import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container p-6 bg-white text-gray-900 mt-4 mx-16">
            <h1 className="text-xl font-bold underline text-center mb-4 font-bold">TERMS AND CONDITIONS</h1>
            <p className="italic mb-6 font-semibold">Last Updated: 30th August 2024</p>
            <p className="mb-6 text-justify font-semibold">
            Welcome to Brefy.AI, owned and operated by Darwiin Pte. Ltd. (<span className="font-bold">"Company,"</span>, <span className="font-bold">"we,"</span> <span className="font-bold">"us,"</span> or <span className="font-bold">"our"</span>). By accessing or using this website or any other Brefy.AI products, software, and services provided to you on, from or through this website (collectively the <span className="font-bold">"Services"</span>), whether automated or otherwise, you agree to be bound by these terms and conditions, the Privacy Policy and any additional terms and conditions that are referenced below or otherwise may apply to specific areas of this website (<span className="font-bold">"Terms"</span>). Please read these Terms carefully before using the Services. If you do not agree to be bound by any of these Terms, you may not use the Services. 
            </p>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">1. ACCEPTANCE</h2>
                <p className="mb-4 text-justify font-semibold">
                    These Terms govern your access and use of the Services offered by us and any information displayed on this website. By using the Services, you are indicating your acceptance of this Terms, which thereby becomes a binding contract between you and the Company. Our acceptance is expressly conditioned upon your assent to all the terms and conditions of this Terms.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    The terms <span className="font-bold">"you,"</span> <span className="font-bold">"your,"</span> and <span className="font-bold">"yourself"</span> shall also include your employees, agents, business representatives, and any other individuals to whom you provide access to the Services through your Account. You are responsible for ensuring that all persons who access the Services through your Account are aware of these Terms and comply with them.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    We reserve the right at our sole discretion to change, modify, add to, supplement, suspend, discontinue, or delete any of these Terms from time to time and review, improve, modify or discontinue, temporarily or permanently, the Services or any content or information through the Services at any time, effective with or without prior notice and without any liability to us.. All changes are effective immediately when posted. Your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">2. OUR SERVICES</h2>
                <p className="mb-4 text-justify font-semibold">
                We provide a platform that enables users to better understand their meeting attendees by connecting to their calendars, utilizing artificial intelligence to research upcoming events, and generating briefings on each attendee. Our Services are designed to assist users in preparing for meetings by offering insights and information about meeting participants.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">3. REGISTRATION AND ACCOUNT SECURITY</h2>
                <p className="mb-4 text-justify font-semibold">
                    To use certain features or areas of the Services, you will be required to provide personal and/or demographic information as part of a registration or login process and to create an Account (<span className="font-bold">"Account"</span>). In addition, certain features of the Services are only available to our registered users, and to access those areas of the Services, you will be required to log in using your username and password.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    In connection with such registration, we may refuse to grant you, and you may not use, a username (or email address) or screen name that is already being used by someone else, that may be construed as impersonating another person, that belongs to another person, that violates the intellectual property or other rights of any person, that is offensive, or that we rejects for any other reason in our sole discretion. You agree to provide true, accurate, current, and complete information about yourself as prompted by the applicable registration or login form, and you are responsible for keeping such information up-to-date (this includes your contact information, so that we can reliably contact you).
                </p>
                <p className="mb-4 text-justify font-semibold">
                    The information you submit must describe you (you may not impersonate another person or entity), and you may not sell, share, or otherwise transfer your account information.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    If you provide any information that is untrue or inaccurate, not current, or incomplete, or if we suspect that your information is untrue or inaccurate, not current, or incomplete, we may, in our sole discretion, suspend or terminate your right to access any material for which registration is required. Any personally identifiable information supplied hereunder will be subject to the terms of our Privacy Policy.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential. You are responsible for all activity occurring when the Services are accessed through your account, whether authorised by you or not. We are not liable for any loss or damage arising from your failure to protect your password or Account information. You agree to notify us immediately of any unauthorised access to or use of your username or password or any other breach of security. You should use particular caution when accessing your Account from a public or shared computer so that others are not able to view or record your password or other personal information.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">4. PAYMENT TERMS</h2>
                <p className="mb-4 text-justify font-semibold">
                    Some of our Services are subject to payments (<span className="font-bold">"Paid Services"</span>). Please note that any payment terms presented to you in the process of using or signing up for Paid Services are deemed part of this Terms.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    We may use a third-party payment processor (<span className="font-bold">"Payment Processor"</span>) to bill you through a payment account linked to your Account (<span className="font-bold">"Billing Account"</span>). The processing of payments will be subject to the terms, conditions, and privacy policies of the Payment Processor in addition to this Terms. We are not responsible for errors by the Payment Processor. By utilising the Services, you agree to pay us, through the Payment Processor, in accordance with the applicable payment terms. We reserve the right to change its prices and to offer discounts and temporary promotions. You agree that it is your responsibility to maintain a valid, non-expired credit card on file with us while engaging in fee-based activities on our Services.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">5. SUBSCRIPTION SERVICE</h2>
                <p className="mb-4 text-justify font-semibold">
                    The Service may include automatically recurring payments for periodic charges (<span className="font-bold">"Subscription Servce"</span>). If you activate a Subscription Service, you authorise us or our third-party payment processors, to periodically charge, on a going-forward basis and until cancellation of either the recurring payments or your account, all accrued sums on or before the payment due date for the accrued sums.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    Your account will be charged automatically on the subscription billing date all applicable fees and taxes for the next subscription period, which may be one month or one year depending on the selection you make on your account.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">6. USER CONTENT</h2>
                <p className="mb-4 text-justify font-semibold">
                    <span className="font-bold">"User Content"</span> refers to any content, information, and materials, including text, audio, or visual elements, that you provide, submit, upload, publish, or otherwise make available through the Services to other users. You are solely responsible for your User Content. By using the Services, you agree that you are fully accountable for any User Content you send, transmit, display, or upload. You are also responsible for ensuring that your User Content complies with all applicable laws, including, but not limited to, those requiring permission from third parties to use the User Content and proper acknowledgment of third-party rights. You represent and warrant that you have the necessary rights to upload the User Content to the Services and that your submission does not violate or infringe upon any third party's rights.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    We are not responsible for:
                </p>
                <ul className="alphabet-list pl-10 mb-4 font-semibold">
                    <li>User Content transmitted or viewed using the Services;</li>
                    <li>Any errors or omissions in the User Content; or</li>
                    <li>Any loss or damage of any kind incurred as a result of the authorized use, access, or denial of access to User Content.While we are not obligated to monitor User Content, we reserve the right to remove any User Content at any time without notice if it violates these Terms or any applicable laws. You retain any ownership rights you have in your User Content.</li>
                </ul>
                <p className="mb-4 text-justify font-semibold">
                    Our role is to provide a platform for the distribution and publication of your User Content. You acknowledge and agree that we:
                </p>
                <ul className="alphabet-list pl-10 mb-4 font-semibold">
                    <li>Are not involved in the creation or development of User Content;</li>
                    <li>Disclaim any responsibility or liability for User Content;</li>
                    <li>Are not liable for any claims arising out of or related to User Content;</li>
                    <li>Are not obligated to monitor, review, or remove User Content, but reserve the right to do so at our discretion;</li>
                </ul>
                <p className="mb-4 text-justify font-semibold">
                    By submitting User Content, you represent and warrant that your User Content:
                </p>
                <ul className="alphabet-list pl-10 font-semibold">
                    <li>Will not be false, inaccurate, incomplete, or misleading;</li>
                    <li>Will not infringe on any third party's intellectual property rights, including copyrights, patents, trademarks, trade secrets, or other proprietary rights, nor rights of publicity, personality, or privacy;</li>
                    <li>Will not violate any laws, statutes, ordinances, or regulations, including those related to export control, consumer protection, unfair competition, anti-discrimination, false advertising, anti-spam, or privacy;</li>
                    <li>Will not be defamatory, libelous, unlawfully threatening, or unlawfully harassing;</li>
                    <li>Will not be obscene or contain pornography, including but not limited to child pornography, or be harmful to minors;</li>
                    <li>Will not facilitate human trafficking;</li>
                    <li>Will not support terrorism or terrorist organizations;</li>
                    <li>Will not be fraudulent, false, or misleading;</li>
                    <li>Will not be defamatory, harassing, threatening, or abusive, including any activity that reflects hatred against others based on race, religion, ethnicity, national origin, gender, or sexual orientation;</li>
                    <li>Will not send unauthorized messages, advertising, or spam, including unsolicited promotional or commercial content or other mass solicitation materials;</li>
                    <li>Will not misrepresent your identity or affiliation with any entity or organization or impersonate any other person;</li>
                    <li>Will not harvest, collect, or gather user data without consent;</li>
                    <li>Will not contain viruses, Trojan horses, worms, time bombs, cancelbots, or other harmful computer programming routines intended to damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or personal information;</li>
                    <li>Will not falsely imply employment, direct engagement, or affiliation with us, nor represent you as our representative or agent; and</li>
                    <li>Will not create liability for us or cause us to lose (in whole or in part) the services of our internet service providers or other suppliers.</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">7. REPRESENTATIONS AND WARRANTIES</h2>
                <p className="mb-4 text-justify font-semibold">
                    By using our Services, you represent and warrant that:
                </p>
                <ul className="alphabet-list pl-10 mb-4 font-semibold">
                    <li>You are at least 18 years old or of the legally required age in your jurisdiction to enter into a binding contract; and</li>
                    <li>You have the legal right, authority, and capacity to agree to these Terms and will comply with all of them. If you are entering into this Agreement on behalf of a company or organization, you represent and warrant that you have the authority to bind that entity to these Terms.</li>
                </ul>
                <p className="mb-4 text-justify font-semibold">
                    Additionally, you represent and warrant that:
                </p>
                <ul className="alphabet-list pl-10 mb-4 font-semibold">
                    <li>You have read, understand, and agree to be bound by these Terms and our Privacy Policy to access and use the Services;</li>
                    <li>You will conduct yourself in a professional and responsible manner in your interactions with other users; and</li>
                    <li>You will comply with all applicable local, state, national, or international laws, regulations, and customs when using or accessing the Services and will act in good faith.</li>
                </ul>
                <p className="mb-4 text-justify font-semibold">
                    You agree not to engage in any of the following prohibited activities:
                </p>
                <ul className="alphabet-list pl-10 mb-4 font-semibold">
                    <li>Copying, distributing, or disclosing any part of the Services in any medium, except as permitted by the Services and these Terms;</li>
                    <li>Using any automated systems, including but not limited to "robots," "spiders," "offline readers," etc., to access the Services, other than through the Services’ intended functionalities;</li>
                    <li>Transmitting spam, chain letters, or other unsolicited communications, or attempting to engage in phishing, pharming, pretexting, spidering, crawling, or scraping;</li>
                    <li>Attempting to interfere with or compromise the integrity or security of the system or to decipher any transmissions to or from the servers that run the Services;</li>
                    <li>Violating any applicable laws, regulations, or ordinances, whether local, state, federal, or international;</li>
                    <li>Engaging in or soliciting any unlawful activities;</li>
                    <li>Uploading or transmitting invalid data, viruses, worms, or other harmful software through the Services;</li>
                    <li>Infringing upon or violating our intellectual property rights or the intellectual property rights of others;</li>
                    <li>Impersonating any person or entity or misrepresenting your affiliation with a person or entity, committing fraud, or attempting to conceal your identity;</li>
                    <li>Harassing, abusing, defaming, stalking, threatening, intimidating, or otherwise violating the legal rights (such as rights of privacy and publicity) of other users, visitors, or our staff;</li>
                    <li>Interfering with or compromising the performance, security, or proper functioning of the Services;</li>
                    <li>Uploading or transmitting viruses or any other malicious code;</li>
                    <li>Attempting to decipher, decompile, disassemble, or reverse-engineer any of the software or algorithms used to provide the Services;</li>
                    <li>Bypassing any security features or measures we use to prevent or restrict access to the Services, including features that prevent or restrict the use or copying of any content or enforce limitations on the use of the Services or the content within;</li>
                    <li>Attempting to access unauthorized accounts or collect or track the personal information of others;</li>
                    <li>Using the Services in any way that infringes the rights of any third party; and</li>
                    <li>Encouraging or enabling others to engage in any of the above activities.</li>
                </ul>
                <p className="mb-4 text-justify font-semibold">
                    You represent and warrant that, unless you fully and promptly disclose to us otherwise, you do not have any motive, status, or interest that we might reasonably need to know about regarding your use of the Services. This includes, but is not limited to, using or intending to use the Services for any journalistic, investigative, or unlawful purpose. You agree to promptly disclose to us in writing any such motives, statuses, or interests, whether they exist before registration or arise during your use of the Services.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">8. INTELLECTUAL PROPERTY RIGHTS</h2>
                <p className="mb-4 text-justify font-semibold">
                    The Services and all of its contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, as well as the design, selection, and arrangement of these elements), are owned by us, our affiliates, licensors, or other providers of such material. These are protected by international laws governing copyright, trademark, patent, trade secrets, and other intellectual property or proprietary rights. We, along with our affiliates and licensors, retain all rights, titles, and interests in and to all intellectual property rights associated with the Services. You are prohibited from modifying, copying, reproducing, republishing, uploading, posting, transmitting, translating, selling, creating derivative works from, exploiting, or distributing any material from our Platform or Services in any form or medium (including by email or other electronic means) unless explicitly authorized in these Terms or by the owner(s) of the materials. 
                </p>
                <p className="mb-4 text-justify font-semibold">
                    We grant you a non-sublicensable, non-transferable, non-exclusive, limited license to use the Services in accordance with this Agreement and solely for its intended purposes. Other than the rights to use the Services as set forth herein, you are not granted any other license or rights, including intellectual property or proprietary rights of ours or our licensors. You acknowledge that you have no rights to the Platform, Services, or any other property of ours except as expressly provided in these Terms. All rights not expressly granted by this license are reserved by us. 
                </p>
                <p className="mb-4 text-justify font-semibold">
                    You retain all ownership rights in the User Content. However, by transmitting, uploading, or posting User Content on or through the Services, you grant us a worldwide, non-exclusive, royalty-free license to access, process, copy, export, and display such User Content, but only as reasonably necessary to:
                </p>
                <ul className="alphabet-list pl-10 mb-4 font-semibold">
                    <li>Provide, maintain, and update the Services;</li>
                    <li>Prevent or address service, security, support, or technical issues;</li>
                    <li>Comply with legal requirements; and</li>
                    <li>As expressly permitted in writing by you.</li>
                </ul>
                <p className="mb-4 text-justify font-semibold">
                    You represent and warrant that you have obtained all necessary rights to grant this license to us. We value and appreciate any feedback or suggestions you provide regarding the Services. If you provide us with feedback or suggestions, we may or may not use it. You grant us an unlimited, irrevocable, perpetual, sublicensable, transferable, royalty-free license to use any such feedback or suggestions for any purpose without any obligation or compensation to you. 
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">9. TERM AND TERMINATION</h2>
                <p className="mb-4 text-justify font-semibold">
                    You may stop using our Services at any time. We reserve the right to terminate or suspend your access to or ability to use any or all of our Services at any time, without prior notice or liability, for any reason or no reason. In particular, we may immediately terminate or suspend your Account if you are found to be in breach of these Terms or if your Account has been flagged for copyright infringement. Upon termination, we will refund any prepaid fees covering the remainder of the term of all subscriptions after the effective date of termination. Termination of your access to and use of the Services does not relieve you of any obligations that arose or accrued prior to such termination, nor does it limit any liability that you may have to us or any third party.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">10. DISCLAIMER</h2>
                <p className="mb-4 text-justify font-semibold">
                    Your use of the Services and any content or products therein is at your own risk. The Services and any content or products therein are provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. Neither we nor any person associated with us makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Services. Without limiting the foregoing, neither we nor anyone associated with us represents or warrants that the Services and any content or products therein will be accurate, reliable, error-free, or uninterrupted; that defects will be corrected; that our site or the server that makes it available are free of viruses or other harmful components; or that the Services and any content or products therein will otherwise meet your needs or expectations. To the fullest extent provided by law, we hereby disclaim any and all representations, warranties, or guarantees of any kind, express, implied, statutory, or otherwise, including, but not limited to, any warranties of merchantability, noninfringement, and fitness for a particular purpose.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    We may alter, suspend, add to, or discontinue the Services in whole or in part at any time for any reason, without notice or cost. We assume no responsibility for your ability to (or any costs or fees associated with your ability to) obtain access to the Services. We do not assume any liability for the failure to store or maintain any user content, user communications, account information, or personal settings. The Services may become unavailable due to maintenance or malfunction of computer equipment, servers, or other reasons.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    You are solely responsible for any and all acts or omissions taken or made in reliance on the Services or the information contained therein, including inaccurate or incomplete information. You agree that we have made no agreements, representations, or warranties other than those expressly set forth in these Terms.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">11. LIMITATION ON LIABILITY</h2>
                <p className="mb-4 text-justify font-semibold">
                    To the fullest extent provided by law, in no event will we, our affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the Services or any content or products therein, including any direct, indirect, special, incidental, consequential, or punitive damages, including, but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable. The foregoing does not affect any liability that cannot be excluded or limited under applicable law.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">12. INDEMNIFICATION</h2>
                <p className="mb-4 text-justify font-semibold">
                    You agree to defend, indemnify, and hold harmless us, our affiliates, licensors, and service providers, and our and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms or your use of the Services, including, but not limited to, your User Content, any use of the content, services, and products other than as expressly authorised in these Terms, or your use of any information obtained from this website.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">13. LINKS TO OTHER SITES</h2>
                <p className="mb-4 text-justify font-semibold">
                    From time to time, this website may include links to third-party websites that are not under our control. These links are provided for additional information and do not imply that we endorse these websites or their content, nor that we are affiliated with them in any way. We are not responsible for the content of any linked website. It is your responsibility to review the terms and conditions of any other website you visit. Any further links provided on the website or through the Services have not been investigated, verified, monitored, or endorsed by us and are used at your own risk.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">14. CONFIDENTIAL INFORMATION</h2>
                <p className="mb-4 text-justify font-semibold">
                    "Confidential Information" refers to any and all trade secrets, confidential and proprietary information, and all other information and data that is not generally known to the public or other third parties who could derive value, economic or otherwise, from its use or disclosure. Confidential Information includes, but is not limited to, technical data, know-how, research, product plans, products, services, customers, markets, software, developments, inventions, processes, formulas, technology, designs, drawings, engineering, hardware configuration information, marketing, finances, strategic and other proprietary information related to our business, operations, or properties, including information about our staff, users, partners, or other business information disclosed directly or indirectly in writing, orally, or by observation.
                </p>
                <p className="mb-4 text-justify font-semibold">
                    You acknowledge that Confidential Information is a valuable, special, and unique asset of our company and agree that you will not disclose, transfer, use (or seek to induce others to disclose, transfer, or use) any Confidential Information for any purpose other than as necessary to use the Services in accordance with these Terms. If applicable, you may disclose Confidential Information to your authorised employees and agents, provided that they are also bound to maintain the confidentiality of such information. You shall promptly notify us in writing of any circumstances that may constitute unauthorised disclosure, transfer, or use of Confidential Information and will use best efforts to protect Confidential Information from such unauthorised actions. Upon termination of this Terms for any reason, you shall return all originals and any copies of materials containing Confidential Information to us.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">15. LAW AND JURISDICTION</h2>
                <p className="mb-4 text-justify font-semibold">
                    This Terms will be governed by and construed in accordance with the laws of Singapore. Any disputes relating to this document shall be subject to the exclusive jurisdiction of the courts of Singapore.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold underline mb-2">16. NO RIGHTS OF THIRD PARTIES</h2>
                <p className="mb-4 text-justify font-semibold">
                    None of the Terms are enforceable by any persons who are not a party to this Terms.,
                </p>
            </section>


            <footer className="mt-8">
                <h2 className="text-lg font-bold mb-2">Contact us</h2>
                <p className="text-justify font-semibold">
                    If You have any questions about these Terms, you can contact us: {' '}
                    <a href="mailto:support@gridapp.io" className="text-blue-600 underline">support@gridapp.io</a>.
                </p>
            </footer>
        </div>
    );
};

export default TermsAndConditions;
