import React from 'react';
import IGLogo from '../assets/ig_logo.webp';
import FBLogo from '../assets/fb_logo.png';
import LILogo from '../assets/li_logo.webp';
import brefyLogo from '../assets/logoBrefy.svg';

const Footer = () => {
  return (
    <>
        <div className="bg-white w-full p-4 py-6 lg:py-8 border-t border-gray-200">
            <div className="md:flex md:justify-center ">
                <div className="mb-8 mr-16 md:ml-8 md:mb-0 lg:ml-8 xl:ml-16">
                    <div className="flex flex-col">
                        <a href="" className="flex items-center mb-4">
                            <img src={brefyLogo} alt="Brefy Logo" className="w-44 rounded-lg mr-4"/>
                        </a>
                        <div className="flex items-center">
                            <img src={LILogo} alt="Brefy Logo" className="w-6 h-6 rounded-lg mr-8 ml-4"/>
                            <img src={FBLogo} alt="Brefy Logo" className="w-6 h-6 rounded-lg mr-8"/>
                            <img src={IGLogo} alt="Brefy Logo" className="w-6 h-6 rounded-lg mr-8"/>
                        </div>
                    </div>
                </div>
                <div className="mb-14 mr-16 lg:mr-48">
                    <div>
                        <h2 className="text-lg font-bold text-custom-darkblue mb-4">COMPANY</h2>
                        <ul className="text-gray-500 dark:text-gray-400">
                            <li className="mb-4">
                                <a href="" className="hover:underline">About Us</a>
                            </li>
                            <li>
                                <a href="" className="hover:underline">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="md:ml-8 lg:ml-0 mr-20 mb-10 md:mb-36 lg:mb-52">
                    <div>
                        <h2 className="text-lg font-bold text-custom-darkblue">Join our Newsletter</h2>
                        <form className="w-full max-w-sm">
                            <div className="flex items-center border-b border-black">
                                <input type="search" id="default-search" className="self-end bg-transparent w-full text-gray-700 text-sm leading-tight focus:outline-none mb-2" placeholder="Enter Email Address" />
                                <button className="self-end flex-shrink-0 text-sm text-black rounded mb-2" type="button">
                                Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <footer className="bg-white mt">
            <div className="mx-auto w-full max-w-screen-xl">
                <div className="px-4 py-6 flex items-center justify-between">
                    <span className="text-sm text-black dark:text-gray-300 sm:text-center">© <span className="font-bold">Brefy</span> 2024
                    </span>
                    <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                        <a href="" className="hover:underline text-sm text-gray-500 dark:text-gray-300">Website Terms</a>
                        <a href="" className="hover:underline text-sm text-gray-500 dark:text-gray-300">Privacy</a>
                    </div>
                </div>
            </div>
        </footer>
    </>
  );
};

export default Footer;
