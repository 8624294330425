// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alphabet-list {
    list-style: none;
    counter-reset: list;
}

.alphabet-list li {
    position: relative;
    padding-left: 1.5rem;
}

.alphabet-list li::before {
    counter-increment: list;
    content: "(" counter(list, lower-alpha) ") ";
    position: absolute;
    left: 0;
    color: #000;
    font-weight: normal;
}

.semibold {
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsAndConditions.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,4CAA4C;IAC5C,kBAAkB;IAClB,OAAO;IACP,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".alphabet-list {\n    list-style: none;\n    counter-reset: list;\n}\n\n.alphabet-list li {\n    position: relative;\n    padding-left: 1.5rem;\n}\n\n.alphabet-list li::before {\n    counter-increment: list;\n    content: \"(\" counter(list, lower-alpha) \") \";\n    position: absolute;\n    left: 0;\n    color: #000;\n    font-weight: normal;\n}\n\n.semibold {\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
