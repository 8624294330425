// src/App.js
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import Index from './components/Index';
import Dashboard from './components/Dashboard';
import Events from './components/Events';
import Notifications from './components/Notifications';
import Callback from './components/Callback';
import PrivateRoute from './components/PrivateRoute';
import PrivacyPolicy from './components/PrivacyPolicy';
import Axios from "axios";
import { jwtDecode } from "jwt-decode";
import TermsAndConditions from "./components/TermsAndConditions";
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const queryClient = new QueryClient();

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: 1000 * 60 * 60 * 24,
});

function App() {
  const navigate = useNavigate();

  const handleClick = async (e, provider = "google") => {
    e.preventDefault();
    try {
      const response = await Axios.get(`/api/authorize?provider=${provider}`, {
          withCredentials: true,
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type",
          },
      });
      if (response.data.redirect_url) {
          navigate(response.data.redirect_url);
      } else if (response.data.jwt) {
          localStorage.setItem("JWT", response.data.jwt);
          navigate("/dashboard");
      } else if (response.data.authorization_url) {
          window.location.assign(response.data.authorization_url);
      } else {
          console.error("No relevant data found in response");
      }
    } catch (err) {
        console.log(err);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('JWT');
    if (token) {
        localStorage.setItem('JWT', token);
        
        try {
          const decodedToken = jwtDecode(token);

          const userId = decodedToken.email;
          sessionStorage.setItem('user_id', userId);
          navigate('/dashboard');
        } catch (error) {
            console.error('Error decoding JWT:', error);
            navigate('/'); 
        }
    }
}, [navigate]);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Index login={(e) => handleClick(e, "google")} loginOutlook={(e) => handleClick(e, "outlook")} />} />
        <Route path="*" element={<Index login={(e) => handleClick(e, "google")} loginOutlook={(e) => handleClick(e, "outlook")} />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/events/:eventId" element={<PrivateRoute><Events /></PrivateRoute>} />
        <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
