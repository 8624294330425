import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container p-6 bg-white text-gray-900 mt-4 mx-16">
            <h1 className="text-xl font-bold underline text-center mb-4">PRIVACY POLICY</h1>
            <p className="italic mb-6">Last Updated: 07th November 2024</p>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">1. <u>INTRODUCTION</u></h2>
                <p className="mb-4 text-justify font-semibold">
                    The purpose of this Privacy Policy is to inform you of how Brefy.AI, owned and operated by Darwiin
                    Pte. Ltd. (<span className="font-bold">"Company,"</span> <span className="font-bold">"we,"</span> “<span className="font-bold">"us,"</span> or <span className="font-bold">"our"</span>) manage your personal data in accordance
                    with the Singapore Personal Data Protection Act (No. 26 of 2012) (“PDPA”) and other applicable laws.
                    This Privacy Policy applies to any individual whose personal data is in our possession or under our
                    control (collectively <span className="font-bold">"you"</span>, <span className="font-bold">"your"</span>, or <span className="font-bold">"yours"</span>).
                </p>
                <p className="mb-4 text-justify font-semibold">
                    By interacting with us, submitting information to us, or signing up for any products or services
                    offered by us, you consent to our collection, use, and disclosure of your personal data as described
                    in this Privacy Policy.
                </p>
                <p className="text-justify font-semibold">
                    We may update this Privacy Policy from time to time to reflect changes in our practices, industry
                    trends, or legal requirements. Please check this website periodically for any updates.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">2. <u>PERSONAL DATA WE COLLECT</u></h2>
                <p className="mb-4 text-justify font-semibold">
                    <span className="font-bold">"Personal Data"</span> refers to any data that can identify an individual, including data collected directly from you or from third parties. The Personal Data we collect may include, but is not limited to:
                </p>
                <ul className="alphabet-list pl-10 font-semibold">
                    <li>Information you provide when registering for an account, including your name, company name, email address, physical address, phone number, calendar data, meeting data, and any other information you choose to provide;</li>
                    <li>Information used to authenticate your account, such as your account name, password, and any additional details you provide;</li>
                    <li>Information related to transactions or purchases made using our services, including details provided during the transaction process; </li>
                    <li>Information collected through your use of our services, including your name, company name, email address, physical address, phone number, and any other information you provide while using the services;</li>
                    <li>Information you provide via email or through our contact forms, including your name, company name, email address, physical address, phone number, and any other details you decide to share;</li>
                    <li>Information you provide when subscribing to our newsletters or updates, such as your email address and the topics of interest;</li>
                    <li>If you are one of our customers, suppliers, or prospects, we may process personal data such as your name, company name, title, email address, phone number, address, order details, and, where applicable, credit information related to credit registrations and limits; and</li>
                    <li>Limited payment data (non-PCI) related to transactions made through our services.</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">3. <u>PURPOSES FOR COLLECTION, USE, AND DISCLOSURE</u></h2>
                <p className="mb-4 text-justify font-semibold">
                    We use the information you provide to us for the following purposes:
                </p>
                <ul className="alphabet-list pl-10 font-semibold">
                    <li>To administer your use of our services and any accounts you may have with us, including processing transactions, collecting and remitting payment for paid features, and managing your service requests;</li>
                    <li>To personalize your experience by tailoring the content and features of the services to your preferences and interests;</li>
                    <li>To provide you with announcements, updates, new releases, and features related to our services;</li>
                    <li>To provide you with information and offers from us or our partners that we believe may be of interest to you, including newsletters, marketing, and promotional materials;</li>
                    <li>To enforce our Terms and resolve any disputes between users of our services or between users and us;</li>
                    <li>To comply with legal requirements, such as responding to subpoenas, court orders, or other legal processes;</li>
                    <li>To protect our rights, property, and safety, and those of our users and the public, including preventing and addressing security incidents or fraud; and</li>
                    <li>To contact you for feedback regarding the services and improve our offerings based on your input.</li>
                </ul>
                <p className="mt-4 text-justify font-semibold">
                    In addition to the purposes described above, we use the information collected automatically to:
                </p>
                <ul className="alphabet-list pl-10 font-semibold">
                    <li>Monitor and analyse the use of our services and conduct technical administration of our platform;</li>
                    <li>Improve the functionality and performance of our services;</li>
                    <li>Generate and derive insights regarding user behavior, interests, and website usage to better understand and serve our users;</li>
                    <li>Verify that users meet the criteria required to process their requests and ensure compliance with our policies; and</li>
                    <li>Utilise demographic, geo-location, and device-related information, including IP address, device model and ID, address, operating system, application usage data, device time zone, region and language, and click ID, to ensure relevant advertising is presented within the services.</li>
                </ul>
                <p className="mt-4 text-justify font-semibold">
                    We do not transfer, disclose, or share your information with third parties except to deliver our services, comply with legal obligations, protect our rights, or as explicitly outlined in this privacy policy, such as sharing with related corporate entities, service providers, or authorities when required. If required to share information, we ensure it is limited to the minimum necessary.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">4. <u>4.	RETENTION AND DELETION OF DATA</u></h2>
                <p className="text-justify font-semibold">
                    We retain your personal information only as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law. When data is no longer needed, we will delete it securely. You may request deletion of your data at any time by contacting us, and we will respond within 30 days, in accordance with PDPA.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">5. <u>USE OF COOKIES AND TRACKING TECHNOLOGIES</u></h2>
                <p className="text-justify font-semibold">
                    We use cookies and similar technologies to enhance your browsing experience on our website. Cookies help us understand how you use our site and provide tailored content. You can manage your cookie preferences through your browser settings. Note that disabling cookies may affect your ability to access certain features of our website.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">6. <u>THIRD-PARTY LINKS</u></h2>
                <p className="text-justify font-semibold">
                    Our website may contain links to third-party websites. We are not responsible for the privacy practices of these sites. We encourage you to review their privacy policies before providing any Personal Data.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">7. <u>CONTACTING US</u></h2>
                <p className="text-justify font-semibold">
                    For questions, feedback, or to exercise your rights under this Privacy Policy, please contact us at{' '}
                    <a href="mailto:support@gridapp.io" className="text-blue-600 underline">support@gridapp.io</a>.
                    If you wish to withdraw your consent to the use of your Personal Data, please note that this may
                    impact our ability to provide certain services.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-lg font-bold mb-2">8. <u>GOVERNING LAW</u></h2>
                <p className="text-justify font-semibold">
                    This Privacy Policy is governed by the laws of Singapore. Any disputes arising from or in connection
                    with this Privacy Policy shall be subject to the exclusive jurisdiction of the courts of Singapore.
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
