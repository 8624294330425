import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('/api/notifications');
        setNotifications(response.data);
      } catch (error) {
        setError('Failed to load notifications');
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Notifications</h1>
      {notifications.length === 0 ? (
        <p>No notifications</p>
      ) : (
        <ul>
          {notifications.map((notification, index) => (
            <li key={index}>
              <p><strong>Notification ID:</strong> {notification.id}</p>
              <p><strong>User ID:</strong> {notification.user_id}</p>
              <p><strong>Timestamp:</strong> {new Date(notification.timestamp).toLocaleString()}</p>
              <p><strong>Event ID:</strong> {notification.event_id}</p>
              <p><strong>Type:</strong> {notification.type}</p>
              <p><strong>Message:</strong> {notification.message}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Notifications;
